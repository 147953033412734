<template>
	<div class="flex-1 bg-white pa-24 radius-20">

		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">알림</h3>
		</div>
		<div class="mt-30">

			<div class="color-888 size-px-14">새글 피드 알림 항목을 설정하세요.</div>

			<div class="flex-row justify-space-between mt-10">
				<h5 class="color-333">공지글 알림</h5>
				<div class="cell_action" @click="postCartelPush('is_notice')">
					<p class="toggle-group">
						<!-- TODO : 수신 허용시 .on 클래스 추가 -->
						<span class="btn-toggle" :class="{ on: item_cartel.is_notice }"><em class="hide">공지글 알림</em></span>
					</p>
				</div>
			</div>

			<hr class="under-line mt-20 mb-20">

			<div class="color-888 size-px-14">내 소식 알림 항목을 설정하세요.</div>

			<div class="flex-row justify-space-between mt-10">
				<h5 class="color-333">댓글 알림</h5>
				<div class="cell_action" @click="postCartelPushComment('is_comment')">
					<p class="toggle-group">
						<!-- TODO : 수신 허용시 .on 클래스 추가 -->
						<span class="btn-toggle" :class="{ on: item_cartel.is_comment }"><em class="hide">댓글 알림</em></span>
					</p>
				</div>
			</div>

			<hr class="under-line mt-20 mb-20">

		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia076'
		, components: {}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: '카르텔 알림 설정'
					, title: '알림 설정'
					, not_footer: true
					, not_header: true
					, type: 'cartel_sub'
					, bg_contents: 'bg-gray01'
					, cartel: ''
					, is_side: true
				}
				, item_cartel: {
					is_notice: true
					, is_comment: false
				}
			}
		}
		, methods: {

			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						if(this.item_cartel.comment_note_fg == 'Y'){
							this.item_cartel.is_comment = true
						}else{
							this.item_cartel.is_comment = false
						}
						if(this.item_cartel.notice_note_fg == 'Y'){
							this.item_cartel.is_notice = true
						}else{
							this.item_cartel.is_notice = false
						}
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postCartelPushComment: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_comment_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, comment_note_fg: this.item_cartel.is_comment = !this.item_cartel.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postCartelPush: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_notice_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_note_fg: this.item_cartel.is_notice = !this.item_cartel.is_notice ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.program.cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, async created() {
			await this.getCartel()
			await this.getData()
			await this.$bus.$emit('onLoad', this.program)
		}
	}
</script>